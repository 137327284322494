import { Space, Button, Dropdown, Menu, Popconfirm } from "antd";
import {
    EditOutlined,
    DeleteOutlined,
    EllipsisOutlined,
    LockOutlined,
    UnlockOutlined,
    GiftOutlined,
} from "@ant-design/icons";

export default function TableActions({
    onEdit,
    onDelete,
    onDeactivate,
    onActivate,
    onFreeTrial,
    record,
}) {
    let actionMenuItem;

    if (record.is_active && onActivate) {
        actionMenuItem = (
            <Menu.Item key="deactivate" onClick={() => onDeactivate(record)}>
                <span style={{ color: "blue" }}>
                    <LockOutlined /> Deactivate
                </span>
            </Menu.Item>
        );
    } else if (!record.is_active && onDeactivate) {
        actionMenuItem = (
            <Menu.Item key="activate" onClick={() => onActivate(record)}>
                <span style={{ color: "green" }}>
                    <UnlockOutlined /> Activate
                </span>
            </Menu.Item>
        );
    }

    const menu = (
        <Menu>
            {onEdit ? (
                <Menu.Item key="edit" onClick={() => onEdit(record)}>
                    <EditOutlined /> Update
                </Menu.Item>
            ) : (
                ""
            )}

            {onDelete ? (
                <Menu.Item key="delete">
                    <Popconfirm
                        className="popconfirm"
                        title="Sure to delete?"
                        placement="topRight"
                        onConfirm={() => onDelete(record)}
                    // overlaStyle={{ top: "111px" }}
                    >
                        <span style={{ color: "red" }}>
                            <DeleteOutlined /> Delete
                        </span>
                    </Popconfirm>
                </Menu.Item>
            ) : (
                ""
            )}
            {actionMenuItem}

            {onFreeTrial ? (
                <Menu.Item key="freeTrial" onClick={() => onFreeTrial(record)}>
                    <span style={{ color: "orange" }}>
                        <GiftOutlined /> Free Trial
                    </span>
                </Menu.Item>
            ) : (
                ""
            )}
        </Menu>
    );

    return (
        <Space>
            <Dropdown overlay={menu} trigger={["click"]}>
                <Button type="text" icon={<EllipsisOutlined />} />
            </Dropdown>
        </Space>
    );
}

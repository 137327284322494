import { useEffect, useState } from "react";
import useHistorySettings from "../../hooks/useHistorySettings";
import useColumns from "../../hooks/useColumns";
import { generateQuery } from "../../Utils";
import Request from "../../Request";
import { Table, notification, Menu } from "antd";
import { useNavigate } from "react-router-dom"

import "./Playlist.css";
import Drawer from "./Drawer";

import {
    TableAddButton,
    TableSettingsButton,
    TableActions,
    TableResetButton,
    TableText,
    TableDelete,
    TableCSV,
    InputSearchFilterDropdown,
    TableBoolean,
} from "../../components/component";
import { useRequest } from "../../hooks/useRequest";

export default function PlaylistPage() {


    const fieldsType = {
        id: "INTEGER",
        name: 'STRING',
        player_url: 'STRING',
        host: "STRING",
        created_At: 'STRING',
        updated_At: 'STRING'
    };

    const defaultTableState = {
        filteredInfo: {},
        sortedInfo: {},
        page: 1,
        limit: 10,
    };

    const navigate = useNavigate()

    const [tableState, setTableState] = useHistorySettings(defaultTableState);

    const {
        data,
        loading,
        error,
        get,
        add,
        put,
        remove,
        activateDevice,
        deActivateDevice,
        activateFreeTrial,
    } = useRequest("playlist", tableState, fieldsType);


    const addDays = (dateStr) => {
        const date = new Date(dateStr);
        const changeDate = date.toLocaleDateString('en-US');
        return changeDate
    };

    const [visibleDrawer, setVisibleDrawer] = useState(false);

    const [selected, setSelected] = useState(null);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [columns, setColumns] = useState([
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            render: (text, record, index) => <TableText text={text} />,
            sorter: false,
            // filterDropdown: InputSearchFilterDropdown,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text, record, index) => <TableText text={record.username} />,
            sorter: false,
            filterDropdown: InputSearchFilterDropdown,
        },
        {
            title: "Player Url",
            dataIndex: "player_url",
            key: "player_url",
            render: (text, record, index) => <TableText text={record.player_url} />,
            sorter: false,
            filterDropdown: InputSearchFilterDropdown,
        },
        {
            title: "Host",
            dataIndex: "host",
            key: "host",
            render: (text, record, index) => < TableText text={record.host} />,
            sorter: false,
            // filters: [
            //     { text: "Yes", value: true },
            //     { text: "No", value: false },
            // ],
            filterDropdown: InputSearchFilterDropdown,
        },
        {
            title: "Created At",
            dataIndex: "created_At",
            key: "created_At",
            render: (text, record, index) => <TableText text={addDays(record.createdAt)} />,
            // sorter: true,
            filterDropdown: InputSearchFilterDropdown,
        },
        {
            title: "Updated At",
            dataIndex: "updated_At",
            key: "updated_At",
            render: (text, record, index) => <TableText text={addDays(record.updatedAt)} />,
            // sorter: true,
            filterDropdown: InputSearchFilterDropdown,
        },
        {
            title: "Action",
            key: "row__actions",
            fixed: "right",
            width: 100,
            render: (text, record, index) => (
                <TableActions onEdit={showDrawer} onDelete={deleteData} record={record} />
            ),
        },
    ]);

    const [onChangeColumns] = useColumns(columns, setColumns, "$name");


    const onActivateDevice = ({ id }) => {
        activateDevice(id);
    };

    const onDeactivateDevice = ({ id }) => {
        deActivateDevice(id);
    };

    const onActivateFreeTrial = ({ id }) => {
        activateFreeTrial(id);
    };

    const deleteData = ({ id }) => {
        remove(id);
    };

    const deleteSelecteds = () => {
        deleteData({ id: selectedRowKeys });
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const showDrawer = (selected = null) => {
        setSelected(selected);
        setVisibleDrawer(true);
    };

    const hideDrawer = (changed, values) => {
        setVisibleDrawer(false);

        if (changed) {
            if (values.id) {
                console.log("values", values);
                put(values);
            } else {
                add(values);
            }

            setTableState((prev) => {
                return { ...prev };
            });
        }
    };

    const tableOnChange = (pagination, filters, sorter) => {
        let { order, field } = sorter;

        setTableState({
            filteredInfo: filters,
            sortedInfo: { order, field },
            page: pagination.current,
            limit: pagination.pageSize,
        });

        const { query } = generateQuery(
            {
                filteredInfo: filters,
                sortedInfo: { order, field },
                page: pagination.current,
                limit: pagination.pageSize,
            },
            fieldsType
        );

        get(JSON.parse(query));
    };

    const resetTable = () => {
        setTableState(defaultTableState);
    };


    useEffect(() => {
        let _columns = [...columns];
        let { filteredInfo, sortedInfo } = tableState;

        _columns.map((column) => {
            column.filteredValue = filteredInfo[column.key] || null;
            column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
        });

        setColumns(_columns);
    }, [tableState]);

    useEffect(() => {
        if (!visibleDrawer) {
            setSelected(null);
        }
    }, [visibleDrawer]);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 8,
                    marginBottom: 20,
                }}
            >
                <TableSettingsButton
                    columns={columns}
                    onChangeColumns={onChangeColumns}
                />
                <TableCSV data={data} />
                <TableAddButton onClick={() => showDrawer()} />
            </div>

            <Table
                loading={loading}
                rowKey="id"
                columns={columns.filter((column) => column.visible)}
                dataSource={data.rows}
                scroll={{ x: "max-content", y: null }}
                onChange={tableOnChange}
                size="small"
                // rowSelection={{
                //     selectedRowKeys,
                //     onChange: onSelectChange,
                // }}
                pagination={{
                    position: ["bottomCenter"],
                    current: tableState.page,
                    total: data.total,
                    pageSize: tableState.limit,
                    showSizeChanger: true,
                }}
            />

            <Drawer visible={visibleDrawer} onClose={hideDrawer} selectedRow={selected} />
        </div>
    );
}

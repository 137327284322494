import './App.css';
import Root from './components/layout/Root';
import { useNavigate, Navigate } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from './pages/login/login';
import { routes } from './components/configs/routes';
import PlaylistPage from "./pages/playlist/Playlist"


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={routes.Dashboard} element={<Root />}>
          <Route path={routes.Playlist} element={<PlaylistPage />} />
        </Route>
        <Route path={routes.Login} element={<Login />} />
      </Routes>
    </div >
  );
}

export default App;

import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";

const TableAddButton = ({ onClick = () => { }, title = 'Add' }) => {
    return (
        <Button type="primary" style={{ background: '#506383' }} icon={<PlusOutlined />} onClick={onClick}>
            {title}
        </Button>
    )
}
export default TableAddButton;
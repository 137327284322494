import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
// import DevicesPackagePopup from "../pages/payPackage/payPackage";


function Root() {

    const [collapsed, setCollapsed] = useState(false);

    const handleCollapsed = () => {
        setCollapsed((collapsed) => !collapsed);
    };

    const [showPay, setSHowPay] = useState(false)

    const handleClickShowPopup = () => {
        setSHowPay((show) => !show)
    }


    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sidebar collapsed={collapsed} handleCollapsed={handleCollapsed} showPay={showPay} setSHowPay={setSHowPay} />

            <Layout style={{ marginLeft: collapsed ? 80 : 250, transition: "0.3s" }}>
                <Header collapsed={collapsed} handleCollapsed={handleCollapsed} handleShowPackage={handleClickShowPopup} showPay={showPay} />

                {showPay ?
                    // <DevicesPackagePopup />
                    "sometext"
                    :
                    <Layout.Content>
                        <Outlet />
                    </Layout.Content>
                }
            </Layout>
        </Layout>
    );
}

export default Root
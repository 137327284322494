import { useEffect, useState } from "react";

import {
    Drawer,
    Form,
    Button,
    notification,
    Input,
    Switch,
    Select,
} from "antd";
import { createFieldsValue } from "../../Utils";

import { useRequest } from "../../hooks/useRequest";

export default function ({ visible, onClose, selectedRow }) {
    // const { data: devicePackageData } = useRequest("devicePackage");

    const fields = {
        host: {
            type: "STRING",
            rules: [{ required: true, message: "Host is required!" }],
        },
        username: {
            type: "STRING",
            rules: [{ required: true, message: "Username is required!" }],
        },
        password: {
            type: "STRING",
            rules: [{ required: true, message: "Password is required!" }],
        },
    };

    const [devicePackageOptions, setdevicePackageOptions] = useState([]);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        let method = "post";

        if (selectedRow) {
            method = "put";
            values.id = selectedRow.id;
        }

        hideDrawer(true, values);
    };

    const hideDrawer = (changed, values) => {
        onClose(changed, values);
    };

    const filterOptionHandler = (input, option) => {
        return option.label == input;
    };

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(createFieldsValue(selectedRow, fields));
        }
    }, [visible]);

    // useEffect(() => {
    //     if (devicePackageData && devicePackageData.rows) {
    //         setdevicePackageOptions(
    //             devicePackageData.rows.map((item) => {
    //                 return {
    //                     value: item.id,
    //                     label: item.id,
    //                 };
    //             })
    //         );
    //     }
    // }, [devicePackageData]);

    return (
        <Drawer
            title={selectedRow ? "Update" : "Add"}
            placement="right"
            onClose={() => hideDrawer(false)}
            open={visible}
            width={600}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Host"
                    name="host"
                    rules={fields.host.rules}
                >
                    <Input allowClear placeholder="Host" />
                </Form.Item>

                <Form.Item
                    label="Usernam"
                    name="username"
                    rules={fields.username.rules}
                >
                    <Input allowClear placeholder="Username" />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={fields.password.rules}
                >
                    <Input allowClear placeholder="Password" />
                </Form.Item>



                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {selectedRow ? "Update" : "Add"}
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}

import axios from "axios";

const hosts = "https://api.moviepic.io";

let url = {
    login: hosts + "/admin/login",
    user: hosts + "/user/all",
    user_info: hosts + '/user',
    user_biling: hosts + "/devicePayments/getter/user",
    user_devic: hosts + "/device/getter/user"
};


let Request = {
    admin: {
        login: (body) => {
            return request("post", url.login, body);
        },
    },
    users: {
        userData: (body) => {
            return request('get', url.user, body)
        }
    },
    users_info: {
        info: (body) => {
            return request('get', url.user_info, body)
        }
    },
    biling_histry: {
        biling: (body) => {
            return request('get', url.user_biling, body)
        }
    },
    user_devices: {
        devices: (body) => {
            return request('get', url.user_devic, body)
        }
    }
};


const request = async (method, url, params) => {

    let options = {
        url,
        method,
        timeout: 10000,
        headers: {
            "content-type": params instanceof FormData ? "multipart/form-data" : "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };
    if (method == "get") {
        options.params = params;
    } else {
        options.data = params;
    }

    try {
        const response = await axios(options);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export default Request;

import { json } from "react-router-dom";
import axios from "./axios";
import { endpoints } from "./endpoints";

export const Requests = {
    statistic: {
        getPlatformsCard: (params) => {
            console.log("prams: ", params);
            return request("get", endpoints.getPlatformsCard, {}, params);
        },
        getDevicesCard: (params) => {
            return request("get", endpoints.getDeviceCard, {}, params);
        },
        getDevicePaymetsCard: (params) => {
            return request("get", endpoints.getPaymentsCard, {}, params);
        },
        getPlaylistCard: (params) => {
            return request("get", endpoints.getPlylistsCard, {}, params);
        },
        getResellersRegisteredCard: () => {
            return request("get", endpoints.getResellersRegisteredCard);
        },
        getResellersPaymentsCard: () => {
            return request("get", endpoints.getResellerPaymentsCard);
        },
        getDeviceChartInfo: (params) => {
            return request("get", endpoints.getDeviceChartInfo, {}, params);
        },
        getDevicePaymentsChartInfo: (params) => {
            return request("get", endpoints.getDevicePaymentsChartInfo, {}, params);
        },
    },
};



export const request = (method, url, body = {}, params) => {
    if (method === "get" && params) {
        body.params = {
            query: params,
        };
    }
    const data = method === "delete" ? { data: body } : body;

    return axios[method](url, data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.log("err: ", err);
            throw err;
        });
};

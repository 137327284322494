import { Typography } from "antd"

const Text = Typography

const TableText = ({ text = '', style = { width: 100 } }) => {

    return (
        <Text style={style} ellipsis={{ tooltip: text }}>
            {text}
        </Text>
    )
}
export default TableText
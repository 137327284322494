// import { useEffect, useState } from "react";
// import { Drawer, Form, Button, notification, Input, Select, Result } from "antd";
// import { createFieldsValue } from "../../Utils";
// import { useRequest } from "../../hooks/useRequest";
// import { Requests } from "../../api/request";

// export default function ({ visible, onClose, selectedRow }) {

//     const fields = {
//         first_name: {
//             type: "STRING",
//             rules: [{ required: true, message: "Email is required!" }],
//         },
//         last_name: {
//             type: "STRING",
//             rules: [{ required: true, message: "Password is required!" }],
//         },
//     };

//     const [form] = Form.useForm();



//     const onFinish = (values) => {

//         Requests.resProfileAdd.profileAdd(values)
//             .then(res => {
//                 console.log(res);
//                 if (res.data) {
//                     let notCredit = res.data
//                     notification.success({ message: notCredit });
//                 } else {
//                     console.log(res);
//                     notification.error({ message: res.data })
//                 }
//                 onClose();
//             }).catch(err => {
//                 console.log(err);
//                 notification.error({ message: err.message })
//                 setErrors("")
//             })
//         form.resetFields();
//     };

//     const hideDrawer = (changed, values) => {
//         onClose(changed, values);
//     };

//     const filterOptionHandler = (input, option) => {
//         return option.label == input;
//     };


//     useEffect(() => {
//         if (visible) {
//             form.setFieldsValue(createFieldsValue(selectedRow, fields));
//         }
//     }, [visible]);



//     return (
//         <Drawer
//             title={selectedRow ? "Update" : "Add"}
//             placement="right"
//             onClose={() => hideDrawer(false)}
//             open={visible}
//             width={600}
//         >
//             <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">

//                 <Form.Item label="First Name" name="first_name" rules={fields.first_name.rules}>
//                     <Input allowClear />
//                 </Form.Item>

//                 <Form.Item label="Last Name" name="last_name" rules={fields.last_name.rules}>
//                     <Input allowClear />
//                 </Form.Item>

//                 <Form.Item>
//                     <Button type="primary" htmlType="submit">
//                         {selectedRow ? "Update" : "Save"}
//                     </Button>
//                 </Form.Item>
//             </Form>
//         </Drawer>
//     );
// }

export default function ({ visible, onClose, selectedRow }) {

}
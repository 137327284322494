export const endpoints = {
    profile: "/admin/profile",
    devicePackage: "/devicePackage",
    devicePayments: "/devicePayments",
    login: "/admin/login",
    file: "/file",
    creditPackage: "/creditPackage",
    reseller: "/reseller",
    resellerPayments: "/resellerPayments",
    getPlatformsCard: "/statistic/card/platforms",
    getDeviceCard: "/statistic/card/devices",
    getPaymentsCard: "/statistic/card/devicePayments",
    getPlylistsCard: "/statistic/card/playlists",
    getResellersRegisteredCard: "/statistic/card/resellersRegistered",
    getResellerPaymentsCard: "/statistic/card/resellerPayments",
    getDeviceChartInfo: "/statistic/chart/devices",
    getDevicePaymentsChartInfo: "/statistic/chart/devicePayments",
    user: "/user/getter",
    device: "/device/getter",
    billing: "/devicePayments/getter",
    channel: "/channel",
    epg: "/epg",
    playlist: "/playlists",
    pricing: "/devicePackage/getter",
    pricingADD: "/devicePackage/"
    // userBiling: "devicePayments/getter/user"
};

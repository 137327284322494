import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    DesktopOutlined,
    LineChartOutlined,
    LogoutOutlined,
    PlayCircleOutlined,
    TransactionOutlined,
    UserOutlined,
    EditOutlined
} from "@ant-design/icons";
// import ProfileTableButton from "../../components/table/profileEdit";
import logo from "../../../assets/images/sprot_logo.webp";
import iptv_sport_group_img from "../../../assets/images/iptv_sport_groop.png";
import { Layout, Menu, Result } from "antd";
import "./Sidebar.css";
import Drawer from "./Drawer";



export default function Sidebar({ collapsed, showPay, setSHowPay }) {



    const navigate = useNavigate();
    const navigateROute = useNavigate()
    const location = useLocation();
    const [current, setCurrent] = useState("");
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [selected, setSelected] = useState(null);

    const showDrawer = (selected = null) => {
        setSelected(selected);
        setVisibleDrawer(true);
    };


    const onClickItem = (e) => {
        setCurrent(e.key);
        if (showPay) {
            setSHowPay(!showPay)
        }
        navigateROute(e.key);
    };


    const logoutHandler = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };


    useEffect(() => {
        setCurrent(location.pathname);
    }, []);



    return (
        <div className="sidebar-section">
            <Layout.Sider
                id="sidebar"
                collapsible={null}
                collapsed={collapsed}
                collapsedWidth={80}
                width={250}
            >
                <div className={`sidebar-logo-section ${collapsed ? "collapsed" : "expanded"}`}>
                    {/* <img className="iptv_sport_img" src={collapsed ? iptv_sport_group_img : logo} alt="logo" /> */}
                </div>

                <div className="sidebar-menu">
                    <Menu mode="inline" onClick={onClickItem} selectedKeys={[current]}>
                        <Menu.Item key="/dashboard/playlist" >
                            <span class="material-symbols-outlined">
                                playlist_play
                            </span>
                            <p className="dashboard-text">Playlist</p>
                        </Menu.Item>
                    </Menu>

                    <div className="logout-item">
                        {/* <div className="profile_edit">
                        </div> */}
                        <span className="borders"></span>
                        <Menu mode="inline" selectedKeys={current} onClick={logoutHandler}>
                            <Menu.Item key="/dashboard/logout" >
                                <span class="material-symbols-outlined">
                                    logout
                                </span>
                                <p className="dashboard-text">Logout</p>
                            </Menu.Item>
                        </Menu>
                    </div>

                    <Drawer
                    // visible={visibleDrawer}
                    // onClose={hideDrawer}
                    // selectedRow={selected}
                    />
                </div>
            </Layout.Sider>
        </div>
    );
}






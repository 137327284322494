import "./login.css"
// import logo_image from "../../assets/xpos_app_3.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Request from "../../Request";
// import { host, routes } from "../../components/configs/routes";
import iptv_sport_logo from "../../assets/images/sprot_logo.webp"



function Login() {

    const [loading, setLoading] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState({
        show: false,
        message: ''
    });


    const handleChangeInputField = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email === '') {
            setError({
                ...error,
                show: true,
                message: 'Email is empty'
            });
            setLoading(false);
            return;
        } else if (password === '') {
            setError({
                ...error,
                show: true,
                message: 'Password is empty'
            });
            setLoading(false);
            return;
        }

        setLoading(true)

        try {
            const data = await Request.admin.login({ email, password });
            localStorage.setItem('token', data.token)
            if (data.token) {
                console.log(localStorage.setItem("data.token:", data.token))
                navigate('/dashboard/playlist')
            } else {
                setErrMessage('Invalid email or password try again.')
            }
        } catch (error) {
            setErrMessage(error.message || 'Request is not valid')
            setLoading(false)
        }
    };



    return (
        <div className="_login">
            <div className="content">
                <div className="loginLeyout">
                    {/* <img className="sport_img" src={iptv_sport_logo} alt="" /> */}
                    <div className="login_content">
                        <div className="form_input">
                            <div className="login_input">
                                <input
                                    name='email'
                                    className="input_fild"
                                    placeholder="email"
                                    type="text"
                                    value={email}
                                    onChange={handleChangeInputField}
                                />
                            </div>
                            <div className="login_input">
                                <input
                                    name="password"
                                    className="input_fild"
                                    placeholder="password"
                                    type="text"
                                    value={password}
                                    onChange={handleChangeInputField}
                                />
                            </div>
                        </div>
                        <div className="login_item">
                            <button className="login_button" onClick={handleSubmit}>{loading ? <div class="loader"></div> : <p>Login</p>}</button>
                        </div>
                        {
                            error.show && <p className="eror_message">{error.message}</p>
                        }
                    </div>
                    {
                        <p className="validation_message">{errMessage}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default Login
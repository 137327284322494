import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

export default function TableCSV({ data }) {
    const handleExport = () => {
        const replacer = (key, value) => (value === null ? "" : value);
        const header = Object.keys(data[0]);
        let csv = data.map((row) =>
            header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")
        );
        csv.unshift(header.join(","));
        csv = csv.join("\r\n");

        const blob = new Blob([csv], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "data.csv";
        link.click();
    };

    return (
        <div>
            <Button
                onClick={handleExport}
                disabled={data?.length === 0}
                type="primary"
                icon={<DownloadOutlined />}
            >
                Export CSV
            </Button>
        </div>
    );
}

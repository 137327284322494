import axios from "axios";
import { request } from "./request";
// const ApiHostFootbal = process.env.API_HOST

const apiHost = "https://api.moviepic.io"
let epg_service = "http://95.111.230.51:23403"

axios.defaults.baseURL = apiHost;

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    const authorizationToken = token ? `Bearer ${token}` : "";
    config.headers.Authorization = authorizationToken;
    return config;
});

export default axios;


// import axios from 'axios';

// const footballApi = axios.create({
//     baseURL: 'https://footballiptv-api.productgid.com'
// });

// const epgServiceApi = axios.create({
//     baseURL: 'http://95.111.230.51:23403'
// });

// footballApi.interceptors.request.use((config) => {
//     const token = localStorage.getItem('token');
//     const authorizationToken = token ? `Bearer ${token}` : '';
//     config.headers.Authorization = authorizationToken;
//     return config;
// });

// epgServiceApi.interceptors.request.use((config) => {
//     const token = localStorage.getItem('token');
//     const authorizationToken = token ? `Bearer ${token}` : '';
//     config.headers.Authorization = authorizationToken;
//     return config;
// });


// export { footballApi, epgServiceApi };

import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { request } from "../api/request";
import { endpoints } from "../api/endpoints";
import { generateQuery } from "../Utils";
import { notification } from "antd";


export function useRequest(url, filteres = {}, fieldsType = {}) {

    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchUrl = endpoints[url];

    useEffect(() => {
        async function fetchData(queryParams) {
            if (!fetchUrl) return;

            setLoading(true);
            setError(null);

            try {
                let params = JSON.stringify(queryParams);
                if (!params) {
                    params = JSON.stringify({
                        pagination: 1,
                        sort: ["id", "DESC"],
                    });
                }

                params = { ...params }

                queryParams.sort = ["id", "DESC"];
                const res = await request("get", fetchUrl, params, {});
                setData(res.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }

        let qParams = searchParams.get("query");

        if (!qParams) {
            qParams = JSON.stringify(filteres);
        }

        const { query } = generateQuery(JSON.parse(qParams), fieldsType);

        fetchData(JSON.parse(query));
    }, [fetchUrl]);

    async function get(queryParams) {
        setLoading(true);
        setError(null);

        try {
            queryParams.sort = ["id", "DESC"];
            // queryParams = JSON.stringify(queryParams);

            if (!queryParams) {
                // queryParams = JSON.stringify({
                //     pagination: 1,
                //     sort: ["id", "DESC"],
                // });
                queryParams = {
                    pagination: 1,
                    sort: ["id", "DESC"],
                };
            }

            const res = await request("get", fetchUrl, {}, queryParams);

            setData(res.data);
            return res;
        } catch (err) {
            setError(err);
            throw err;
        } finally {
            setLoading(false);
        }
    }

    async function getProfile(callback, errCallback) {
        setLoading(true);
        setError(null);

        try {
            const res = await request("get", fetchUrl);

            if (callback) {
                callback(res.data);
            } else {
                setData(res.data);
            }

            return res;
        } catch (err) {
            setError(err);
            throw err;
        } finally {
            setLoading(false);
        }
    }

    async function updateProfile(body, callback, errCallback) {
        setLoading(true);
        setError(null);

        try {
            const res = await request("put", fetchUrl, body);

            if (callback) {
                callback(res.data);
            } else {
                setData(res.data);
            }

            return res;
        } catch (err) {
            setError(err);

            if (errCallback) {
                errCallback(err);
            } else {
                notification.error({ message: err.message });
                throw err;
            }
        } finally {
            setLoading(false);
        }
    }

    async function add(body, callback, errCallback) {
        setLoading(true);
        try {
            const res = await request("post", fetchUrl, body);
            console.log(res);
            console.log("responce is success:", res.data.message);
            notification.success({ message: res.data.message });
            if (callback) {
                callback(res.data);
                console.log(res);
            } else {
                const queries = JSON.parse(searchParams.get("query"));

                const { query } = generateQuery(queries, fieldsType);
                get(JSON.parse(query));
                console.log("this is my request:", res);
                console.log("query params:", query);
            }

            return res;
        } catch (err) {
            setError(err);
            console.log("some error :", err);
            if (errCallback) {
                errCallback(err);
            } else {
                notification.error({ message: err.message });
                throw err;
            }
        } finally {
            setLoading(false);
        }
    }

    async function put(body, callback, errCallback) {
        setLoading(true);

        try {
            const reqUrl = fetchUrl + `?id=${body.id}`;
            delete body.id;
            console.log("body: ", body);

            console.log("reqUrl", reqUrl);
            const res = await request("put", reqUrl, body);

            notification.success({ message: res.data.message });
            if (callback) {
                callback(res.data);
            } else {
                const queries = JSON.parse(searchParams.get("query"));

                const { query } = generateQuery(queries, fieldsType);
                get(JSON.parse(query));
            }

            return res;
        } catch (err) {
            setError(err);

            if (errCallback) {
                errCallback(err);
            } else {
                notification.error({ message: err.message });
                throw err;
            }
        } finally {
            setLoading(false);
        }
    }

    async function remove(id, callback, errCallback) {
        console.log("playlist delete");
        const removeUrl = Array.isArray(id) ? fetchUrl + "/many" : "/playlists" + `?id=${id}`;
        const body = Array.isArray(id) ? { ids: id } : {};

        setLoading(true);

        try {
            console.log("removeUrl: ", removeUrl);
            console.log("body: ", body);

            const res = await request("delete", removeUrl);
            console.log(res);
            notification.success({ message: res.data.message });
            if (callback) {
                console.log("callback", callback);
                console.log("res data: ", res.data);
                console.log("res data: ", res);
                callback(res.data);
            } else {
                console.log("test 1");
                console.log("search params", searchParams.get("query"));
                const queries = JSON.parse(searchParams.get("query"));
                console.log(queries, "queriesssss");

                const { query } = generateQuery(queries, fieldsType);
                console.log(query, "query");

                get(JSON.parse(query));
            }

            return res;
        } catch (err) {
            console.log("errorrr", err.message);

            setError(err);

            if (errCallback) {
                errCallback(err);
            } else {
                notification.error({ message: err.message });
                throw err;
            }
        } finally {
            setLoading(false);
        }
    }

    async function removeImage(path) {
        setLoading(true);

        try {
            const res = await request("delete", url, { path });
            setData(res);
            return res;
        } catch (err) {
            setError(err);
            throw err;
        } finally {
            setLoading(false);
        }
    }

    async function checkToken(callback = () => { }, errCallback = () => { }) {
        const token = localStorage.getItem("token");

        try {
            const res = await request("post", url, { token });

            if (callback) {
                callback(res.data);
            }

            return res;
        } catch (err) {
            setError(err);
            console.log(err);

            if (errCallback) {
                errCallback(err);
            } else {
                notification.error({ message: err.message });
                throw err;
            }
        }
    }

    async function activateDevice(id, callback, errCallback) {
        try {
            console.log("id: ", id);
            const freeTrialUrl = fetchUrl + `/activate?user_id=${id}`;
            const body = { id: id };

            setLoading(true);

            const res = await request("post", freeTrialUrl, body);

            if (callback) {
                callback(res.data);
            } else {
                const queries = JSON.parse(searchParams.get("query"));

                const { query } = generateQuery(queries, fieldsType);

                get(JSON.parse(query));
            }

            return res;
        } catch (err) {
            setError(err);

            notification.error({ message: err.message });
            throw err;
        } finally {
            setLoading(false);
        }
    }

    async function deActivateDevice(id, callback, errCallback) {
        try {
            console.log("id: ", id);
            const freeTrialUrl = fetchUrl + `/deactivate?user_id=${id}`;
            const body = { id: id };

            setLoading(true);

            const res = await request("post", freeTrialUrl, body);

            if (callback) {
                callback(res.data);
            } else {
                const queries = JSON.parse(searchParams.get("query"));

                const { query } = generateQuery(queries, fieldsType);
                get(JSON.parse(query));
            }

            return res;
        } catch (err) {
            setError(err);

            notification.error({ message: err.message });
            throw err;
        } finally {
            setLoading(false);
        }
    }

    async function activateFreeTrial(id, callback, errCallback) {
        try {
            const freeTrialUrl = fetchUrl + `/freeTrial?id=${id}`;

            setLoading(true);

            const res = await request("post", freeTrialUrl);

            if (callback) {
                callback(res.data);
            } else {
                const queries = JSON.parse(searchParams.get("query"));

                const { query } = generateQuery(queries, fieldsType);
                get(JSON.parse(query));
            }

            return res;
        } catch (err) {
            setError(err);

            notification.error({ message: err.message });
            throw err;
        } finally {
            setLoading(false);
        }
    }

    return {
        data,
        error,
        loading,
        get,
        getProfile,
        updateProfile,
        add,
        put,
        remove,
        removeImage,
        checkToken,
        activateDevice,
        deActivateDevice,
        activateFreeTrial,
    };
}

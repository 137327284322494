import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    RedoOutlined
} from "@ant-design/icons";

import { Drawer, Form, Button, notification, Input, Select, Result, Menu } from "antd";
import "./Header.css"



export default function Header({ collapsed, handleCollapsed, handleShowPackage, showPay }) {

    const [loading, setLoading] = useState(false)


    return (
        <>
            <Layout.Header
                className="root-header"
                style={{
                    padding: 0,
                    left: collapsed ? 80 : 250,
                    transition: "0.3s",
                    width: `calc(100% - ${collapsed ? 80 : 250}px)`,
                }}
            >
                {collapsed ? (
                    <MenuUnfoldOutlined
                        className="trigger"
                        onClick={handleCollapsed}
                    />
                ) : (
                    <MenuFoldOutlined
                        className="trigger"
                        onClick={handleCollapsed}
                    />
                )}
            </Layout.Header>
        </>
    );
}


